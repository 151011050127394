@import "design-system/src/styles/_variables.scss";

.top-right-items {
  display: flex;
  align-items: center;

  .user-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    margin: 0 24px 0 0;

    .display-role {
      display: flex;

      .sub-title {
        font-size: 12px;
        color: $content-description;

        .role {
          text-transform: capitalize;
        }
      }
    }

    p {
      line-height: 1.5;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
