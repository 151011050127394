@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.booking-requests-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }

  h3 {
    font: $WebH3;
    margin-bottom: 0;
  }

  h4 {
    font: $WebH4;
    margin-bottom: 0;
  }

  .table-title-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $content-description;
    }
  }

  table {
    border: 1px solid $surface-static-process-default !important;
    border-radius: 10px;

    thead {
      th {
        background: $surface-static-process-default !important;
      }
    }
    tbody {
      td:first-child {
        text-align: center !important;
      }
    }
    tr {
      .ant-table-cell {
        padding: 17px 8px;
      }
    }

    .dropoff-point-text {
      span {
        font-size: 14px;
      }
    }
  }

  .detail-title {
    font: $WebH2;
  }
  .update-at {
    font-size: 14px;
    color: $content-description;
  }
  .fix-content {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
  }
}



.booking-request-card-content{
  font-size: 1rem !important;
  color: #5C6372;
}

.booking-request-card-title{
  font-weight: 500 !important;
  font-size: 26px;
}

.booking-request-status-card-cover{
   border: 1px dashed #D3D7E1
}