.select-company-content {
  height: calc(100vh - 64px);

  display: flex;
  justify-content: center;
  align-items: center;

  .select-company-card {
    width: 470px;
    padding: 32px 56px;
    text-align: center;
  }

  .title {
    font-size: 24px;
    color: #000;
  }

  .description {
    font-size: 16px;
    color: #000;
    opacity: 0.6;
    padding-bottom: 20px;
  }

  Button {
    width: 100%;
    height: 100%;
    padding: 16px;
    margin: 8px 0;
    border-radius: 8px;
    font-size: 16px;
  }
}
