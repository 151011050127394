.wrap-password-hint {
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      color: red;
      span {
        margin-right: 8px;
      }
    }
  }
}
