@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.epod-report-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }

  h3 {
    font: $WebH3;
  }

  h4 {
    font: $WebH4;
  }

  .filter-form-search {
    display: flex;
    margin: 16px 0 0 0;

    .ant-form-item-row > .ant-form-item-label {
      display: flex;
      margin-bottom: 8px;

      label {
        height: fit-content;
      }
    }

    .ant-form-item {
      display: flex;
      margin-bottom: 0;
    }

    .date-picker-input {
      width: 280px;
    }
  }

  .content-card {
    margin: 16px 0 0 0;
  }

  .list-epod-report-card {
    margin-top: 16px;

    .table-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin-bottom: 0;
      }

      p {
        margin-bottom: 0;
        color: $content-description;
        font-weight: 400;
      }

      .title-left {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .epod-report-table {
    thead {
      th {
        text-align: center !important;
        background: $surface-static-process-default !important;
      }
    }

    .ant-table-container {
      border-radius: 8px;
      border: 1px solid $border-default;
    }

    tbody > tr > .ant-table-cell {
      padding: 8px;
    }

    .unread-row {
      background-color: $surface-static-ui-disabled;
    }
  }

  .detail-table-top-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;

    h3 {
      margin-bottom: 0;
    }

    .search-form-search {
      display: flex;

      .ant-form-item-row > .ant-form-item-label {
        display: flex;
        margin-bottom: 8px;

        label {
          height: fit-content;
        }
      }

      .ant-form-item {
        display: flex;
        margin-bottom: 0;
      }
    }
  }

  .task-percentage,
  .task-completed-percentage {
    width: 220px;
    padding: 8px 8px 0 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;

    .show-detail {
      h4 {
        margin: -5px 0;
      }

      span {
        margin-bottom: -10px;
      }

      &.disable {
        h4 {
          color: $content-disabled;
        }
      }
    }

    h4 {
      color: #006d2e;
      margin-bottom: 0;
    }

    span {
      color: #acacac;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .top-right-content {
    display: flex;
    align-items: flex-end;

    p {
      font: $WebBody2;
      margin-bottom: 0px;
      padding-right: 8px;
    }
  }

  .status-issue-report {
    display: inline-flex;
    width: 13px;
    height: 13px;
    align-items: center;
    border-radius: 16px;
    background: #fff2f2;
    justify-content: center;

    .icon {
      color: #e74747;
      text-align: center;
      font-size: 10px;
      font-weight: 900;
      margin: 0;
    }
  }

  .status-tag {
    span {
      font-size: 14px;
      text-transform: capitalize;
    }

    &.error {
      background: #ffeded;
      color: #a83326;
    }

    &.success {
      background: #e0fde8;
      color: #4bc36d;
    }
  }
}
