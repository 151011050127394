.cannot-connect-tomtommap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
  }

  .error-occurred {
    color: #667080;
    margin-top: 10px;
  }

  .please-try-again {
    color: #a3a9b3;
  }
}
