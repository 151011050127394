.report-detail-content {
  .card-border {
    height: 100%;
    border-radius: 16px;
    border: 1px solid #dcdcdc;
    .ant-card-body {
      padding: 0;
    }
  }

  .title-label {
    font-size: 16px;
  }

  .title-label-history {
    min-width: 200px;
  }

  .text-label {
    font-size: 16px;
    display: flex;
    align-items: flex-end;
  }

  .text-label-issue {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }

  .text-issue {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }

  .ant-collapse {
    border-radius: 4px;
    border: 1px dashed var(--palette-gray-neutral-80, #d1d4d9);
  }

  .collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div:nth-child(1) {
      color: #667080;
    }
    .text-have-image {
      color: #41a447;
      font-size: 16px;
      opacity: 0.7;
    }
    .text-no-image {
      color: #bebec0;
      font-size: 14px;
    }
  }

  .image-detail {
    .ant-image {
      .ant-image-img {
        width: 339px;
        height: 225px;
      }
    }
  }

  .toolbar-wrapper {
    position: fixed;
    bottom: 32px;
    left: 50%;
    padding: 0px 24px;
    color: #fff;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    transform: translateX(-50%);
  }

  .toolbar-wrapper .anticon {
    padding: 12px;
    cursor: pointer;
  }

  .toolbar-wrapper .anticon[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .toolbar-wrapper .anticon:hover {
    opacity: 0.3;
  }

  .ant-card-body {
    button {
      min-width: 120px;
    }
  }
}
.report-issue-box {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: #f4f5f8;
  .mr-8 {
    margin-right: 8px;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
  }
}

.ant-modal-title {
  .report-issue-modal-title {
    font-size: 20px;
    border-bottom: 1px solid #dcdcdc;
    padding: 8px;
  }
}

.ant-modal-body {
  .report-issue-modal-body {
    .buttom-line {
      border-bottom: 1px solid #dcdcdc;
    }
    .flex-8 {
      display: flex;
      padding: 8px 0;
      font-size: 16px;
      div:nth-child(1) {
        width: 188px;
        color: #757575;
      }
    }
  }
}

.modal-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.modal-body {
  font-size: 16px;
}
