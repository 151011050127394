.wrap-google-map {
  width: 100%;
  height: calc(100vh - 305px);
  position: relative;

  &.not-active {
    > div {
      opacity: 0.2;
    }
  }

  span.cannot-found-text {
    display: inline;
    position: absolute;
    left: calc(100% / 2 - 90px);
    z-index: 1;
    top: 50%;
  }
}

.wrap-transport-detail {
  margin-top: 30px;
  .ant-descriptions-header {
    margin-bottom: 12px;
  }
  .wrap-badge-status {
    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }
  .ant-descriptions-item-label {
    background-color: #fafafa;
  }
  .ant-descriptions-item-content {
    background-color: #ffffff;
    &.dc-code {
      span {
        text-decoration: underline;
        display: inline-block;
        cursor: pointer;
      }
    }
    &.mobile-number {
      a {
        color: initial;
      }
    }
  }
  .car-position {
    cursor: pointer;
    img {
      width: 35px;
      height: 35px;
      &.inactive {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  p {
    margin-bottom: 0;

    span.driver-status {
      &::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 6px;
        margin-left: 4px;
      }
      &.active {
        color: #4fc626;
        &::before {
          background-color: #4fc626;
          content: "";
        }
      }
      &.inactive {
        color: #c62626;
        &::before {
          background-color: #c62626;
          content: "";
        }
      }
    }
  }
}

.gps-tracking {
  section {
    margin-bottom: 15px;
  }
}

.top-navigate {
  margin-bottom: 15px;
  button {
    background-color: transparent;
    padding-left: 0;
    border: none;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.wrap-store-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  .stop {
    background-color: #8b0000;
    font-size: 12px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 5px;
  }
}

.gps-wrap-action {
  text-align: center;
}

.form-update-status-wrap-reason {
  textarea {
    margin-top: 10px;
    width: 100%;
  }
  .ant-radio-wrapper {
    width: 100%;
    > span:last-child {
      width: 100%;
    }
  }
}

.update-status-detail {
  margin-bottom: 18px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
  p {
    &:first-child {
      font-size: 16px;
    }
    &:last-child {
      text-transform: capitalize;
    }
    margin-bottom: 0;
  }
}

.confirm-message-update-status {
  p {
    margin-bottom: 0;
  }
}
.modal-confirm-last-step {
  .modal-title-last {
    margin-bottom: 0;
    span {
      text-decoration: underline;
      text-transform: capitalize;
    }
  }
}

.tracking-number-group {
  .tracking-number-text {
    margin-right: 10px;
  }

  a {
    color: #000;
    &:hover {
      color: #000;
    }
  }
}

.wrap-top-title-transport-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.modal-edit-transport-detail {
  .ant-modal-body {
    padding-top: 24px;
  }
  .ant-modal-footer {
    padding-top: 14px;
  }
}

.wrap-table-list {
  margin-top: 30px;
  .wrap-top-action-table {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
}

.modal-edit-stop {
  .ant-modal-body {
    padding-top: 12px;
  }
  .ant-table-content {
    padding-bottom: 12px;
  }
}

.ant-menu-item-selected {
  background-color: #f0f0f0;
}

#dc-marker {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-image: url("../../assets/images/icon/icon-dc.png");
}

#store-marker {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-image: url("../../assets/images/icon/icon-store.png");
}

#truck-marker {
  width: 35px;
  height: 35px;
  background-size: cover;
  background-image: url("../../assets/images/icon/icon-car.png");
}
