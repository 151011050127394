


.access-token-content{
height: calc(100vh - 64px);
display: flex;
justify-content: center;
align-items: center;
.access-token-card {
    width: 470px;
    padding: 32px 56px;
    text-align: center;
  }
}