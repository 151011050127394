.page-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0px;
}

.card-map {
  margin-top: 8px;
  width: 100%;

  .map-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.marker-popup {
  position: absolute;
  min-width: 425px;
  top: 0;
  right: 0;
  background-color: white;
  padding: 12px;
  margin: 12px;
  border-radius: 8px;

  p {
    font-size: 14px;
    font-style: normal;
  }

  .marker-popup-select-load {
    p {
      margin-bottom: 0px;
    }
  }
}

.return-eta-marker-popup-info-card {
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  .return-eta-info-sync-status {
    display: flex;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .return-eta-info-sync-status-detail {
      display: flex;
      align-content: center;
      align-items: center;
      padding: 0 0 10px 0;

      .last-update-time {
        font-size: 14px;
        color: #757575;
      }
    }

    p.detail-title {
      font-size: 14px;
      font-style: normal;
      margin-bottom: 4px;
    }
  }

  .info-eta {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 0 10px 0;
  }

  p {
    font-size: 14px;
    font-style: normal;
    margin-bottom: 0px;
  }
}

.marker-popup-detail-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;

  p.detail-title {
    font-size: 14px;
    font-style: normal;
    margin-bottom: 4px;
  }

  .marker-popup-detail-section-info {
    padding: 0px 12px;

    .detail-section-info {
      display: flex;

      p {
        font-size: 14px;
        font-style: normal;
        margin-bottom: 0px;
      }
    }
  }
}

.marker-popup-detail-section:last-child {
  margin-bottom: 0;
}

.truck-turn-marker {
  position: absolute;
  left: 16px;
  top: 10px;
  .stop {
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    color: #ffffff;
  }
}

#dc-marker {
  height: 78px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/map/warehouse.svg");
}

#active-truck-marker {
  background-image: url("../../assets/images/map/active_marker.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_active_marker.svg");
    .truck-turn-marker {
      left: 14px;
      top: 12px;
    }
  }
}

#inactive-truck-marker {
  background-image: url("../../assets/images/map/inactive_marker.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  &.active {
    background-image: url("../../assets/images/map/focus_inactive_marker.svg");
    .truck-turn-marker {
      left: 14px;
      top: 12px;
    }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.spinner-component {
  background: rgba(0, 0, 0, 1);
}

.return-eta-map {
  width: 100%;
  height: calc(100vh - 380px);
  margin-top: 15px;
}

.filter-return-eta {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.radius-dc {
  width: 220px;
  height: 60px;
  display: flex;
  align-items: flex-end;
  input {
    text-align: center;
  }
}

.map-remark-message {
  margin: 13px 0 0 0;
  background-color: #f4f5f8;
  border: 1px solid #d9deeb;
  .ant-alert-message {
    color: #201c00;
  }
  .anticon-info-circle {
    color: #929cb3;
  }
}

.return-eta-map-tutorial-collapse {
  border-radius: 16px;
  border-style: dashed;
  border-color: #dcdcdc;
  margin-top: 13px;
  padding: 0px 12px;

  .ant-collapse-expand-icon {
    color: #acacac;
  }
}

ul {
  margin: 0;
  padding: 0 0 0 16px;
}

.map-tutorial-collapse-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.map-tutorial {
  display: flex;
  align-items: center;

  .map-tutorial-picture {
    display: flex;
    width: 80px;
    margin: 0 8px;

    img {
      margin-left: 8px;
    }
  }

  .map-tutorial-content {
    margin: 0;
    padding: 8px;
  }
}

.radius-step-button {
  border: solid #006d2e 1px;
  color: #006d2e;
}

.return-eta-title-section {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
