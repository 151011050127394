@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.user-management-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }

  .ant-form-item:first-child {
    padding-left: 0;
  }

  .ant-form-item:last-child {
    padding-right: 0;
  }

  table {
    border: 1px solid $surface-static-process-default !important;
    thead {
      th {
        text-align: center !important;
        background: $surface-static-process-default !important;
      }
    }
    tbody {
      td:first-child {
        text-align: center !important;
      }
      td:nth-child(4),
      td:nth-child(5),
      td:nth-child(7) {
        text-align: center !important;
      }
    }
    tr {
      .ant-table-cell {
        padding: 8px;
      }
    }
  }
  .ant-breadcrumb {
    font-size: 12px;
    li:last-child {
      span {
        color: $content-primary-default;
      }
    }
  }
  h2 {
    font: $WebH2;
    margin-bottom: 0;
  }
  h3 {
    font: $WebH3;
  }
  h4 {
    font: $WebH4;
  }

  .form-company {
    margin: 0 0 16px 0;
  }

  .section-user-role {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px 0;

    h3 {
      margin: 0;
    }
    a {
      padding: 8px 16px;
      color: $content-primary-default;
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .section-permission {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: $core-palette-gray-5;

    h4 {
      font: $WebH4;
    }
  }
  .section-delete-detail {
    font: $WebBody1;
    color: $content-description;
  }
}

.modal-create-user {
  h3 {
    font: $WebH3;
    margin: 0;
  }

  .col-form-name {
    display: flex;
    margin-top: 10px;

    .form-lastname {
      margin-left: 16px;
    }
  }

  .col-form-email {
    padding: 16px 0 0 0;
  }

  .card-section-user-role {
    margin: 12px 0 0 0;

    .form-company {
      margin: 0 0 16px 0;
    }

    .section-user-role {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px 0;

      h3 {
        margin: 0;
      }
      a {
        padding: 8px 16px;
        color: $content-primary-default;
      }
    }
  }

  .section-company-permission {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: $core-palette-gray-5;

    h4 {
      font: $WebH4;
    }
  }
}

.confirm-create-user-modal-content {
  p {
    margin: 0;
  }
}

.success-notification {
  border-radius: 8px !important;
  border: 1px solid $core-palette-success-30 !important;
  background: $core-palette-success-10 !important ;
}

.alert-mesage {
  padding: 12px;

  &.error {
    border: 1px solid $core-palette-danger-30;
    background-color: $core-palette-danger-10;
  }

  h4 {
    font: $WebH4;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.modal-permission {
  h3 {
    font: $WebH3;
    margin: 0;
  }
}

.permission-detail {
  width: 100%;
  border: 1px solid var(--border-default, #d3d7e1);
  border-radius: 8px;
  thead {
    th {
      height: 52px;
      background: var(--surface-static-process-default, #edf0fe);
      border-bottom: 1px solid var(--border-default, #d3d7e1);
      text-align: center;
    }
    th:first-child {
      border-radius: 8px 0 0 0;
    }
    th:last-child {
      border-radius: 0 8px 0 0;
    }
  }
  tbody {
    tr {
      .menu {
        background: var(--surface-static-ui-hover, #f6f7fb);
      }
      td {
        vertical-align: text-top;
        border-bottom: 1px solid var(--border-default, #d3d7e1);
        padding: 8px;
      }
      td:nth-last-child(-n + 4) {
        text-align: center;
        height: 26px;
        padding: 8px;
      }
    }
  }
}
