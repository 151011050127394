@import "layout.scss";

.table-custom-design-system {
  border: 1px solid #EDF0FE;
  border-radius: 8px;
  thead {
    tr {
      th {
        padding: 8px !important;
        height: 68px !important;
        background-color: #EDF0FE !important;
      }
      th:last-child {
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 8px !important;
      }
      td:last-child {
        text-align: center;
      }
    }
    .ant-table-row-selected {
      td {
        background-color: #F6F7FB !important;
      }
    }
  }
}