@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.messaging-status-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }
  h3 {
    font: $WebH3;
  }
  .data-date-text {
    margin-left: 13px;
    font-size: 16px;
    color: #5c6372;
  }
  .search-messaging-status {
    .ant-col {
      display: flex;
      align-items: flex-end;
    }
  }
  .data-total-text {
    font-size: 14px;
    color: #5c6372;
  }
}