@font-face {
  font-family: 'IBM Plex Sans Thai Semibold';
  src: url('../assets/fonts/IBMPlexSansThai-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Thai Medium';
  src: url('../assets/fonts/IBMPlexSansThai-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@font-face {
  font-family: 'IBM Plex Sans Thai Regular';
  src: url('../assets/fonts/IBMPlexSansThai-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
