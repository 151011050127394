@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.delivery-order-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }
  h3 {
    font: $WebH3;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
  .ant-form-item-required::after {
    visibility: visible !important;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*" !important;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
  .data-date-text {
    margin-left: 13px;
    font-size: 14px;
    color: #5c6372;
  }
  .ant-alert-message {
    font-weight: 600;
  }
}
.ant-popover-content {
  .ant-popover-inner {
    padding: 0;
    .ant-menu {
      border-radius: 8px !important;
      li {
        padding: 10px !important;
        font: $WebBody1;
      }
      li:hover {
        background: #F5FFF7 !important;
        color: #006D2E !important;
        border-radius: 0;
      }
      .ant-menu-item-selected {
        background-color: unset !important;
        color: unset;
      }
    }
  }
}