.wrap-geofence-marker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .badge {
    background-color: green;
    color: #ffffff;
    font-size: 12px;
    padding: 0 12px;
    border-radius: 12px;
    margin-bottom: 5px;
    display: inline-block;
  }
}
