.card-component.no-detail {
  justify-content: center;
  align-items: center;
}

.card-component.has-detail {
  align-items: top;
}

.card-component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 14px;
  background-color: #f5f5f5;

  .card-component-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;

    p {
      margin: 0 6px 0 0;
    }
  }

  .card-component-content {
    p {
      font-size: 28px;
      margin: 0;
    }
  }

  .card-component-detail {
    width: 100%;
  }
}

.card-component.clickable {
  cursor: pointer;
}

.detail-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  p {
    font-size: 12px;
    margin: 0;
  }
}
