.wrap-form.form-forgot-password {
    padding: 40px;

    h1 {
        margin-bottom: 30px;
    }
}

.modal-forgot-password-complele {

    .ant-modal-body {
        text-align: center;

        .wrap-modal-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 12px;
        }

        span {
            font-size: 50px;
            margin-bottom: 20px;
        }

        p {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
}