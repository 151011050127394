@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.return-eta-request-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f0f2f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }
  h2 {
    font: $WebH2;
    margin-bottom: 0;
  }
  h3 {
    font: $WebH3;
  }
  h4 {
    font: $WebH4;
  }
  .ant-form-item {
    padding: 0 6px;
    display: flex;
    align-items: baseline;
  }
  .ant-alert-info {
    border-radius: 8px;
    border: 1px solid #D9DEEB;
    background: #F4F5F8;
  }
  .last-update-text {
    font-size: 14px;
    color: #5c6372;
    padding: 12px 0;
  }
  table {
    th:nth-child(1),
    th:nth-child(2), 
    th:nth-child(3),
    th:nth-child(6),
    th:nth-child(7) {
      text-align: center;
    }
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(6),
    td:nth-child(7) {
      text-align: center;
    }
  }
  .ant-alert-message {
    margin-bottom: 0;
  }
}