.management-layout {
  .ant-layout-header {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    img {
      width: 64px;
      height: 64px;
      margin-right: 15px;
      margin-left: 15px;
    }
    button {
      border: none;
      box-shadow: none;
    }
  }
  aside {
    background-color: #ffffff;
  }
  .management-content {
    min-height: calc(100vh - 64px);
    padding: 16px 24px;
  }
}

.ant-layout .ant-layout-sider {
  background-color: #ffffff;
}
