@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.drop-monitoring-content {
  min-height: calc(100vh - 64px);
  padding: 24px 36px;
  background-color: #f5f5f5;

  h1 {
    font: $WebH1;
    margin-bottom: 0;
  }

  h3 {
    font: $WebH3;
    margin-bottom: 0;
  }

  h4 {
    font: $WebH4;
    margin-bottom: 0;
  }

  .drop-monitoring-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .ant-collapse-header {
    display: flex;
    align-items: center !important;
  }

  .drop-monitoring-map {
    margin-bottom: 16px;

    .drop-monitoring-map-label {
      display: flex;
      align-items: center;

      h3 {
        margin-bottom: 0;
        margin-right: 8px;
      }

      p {
        margin-bottom: 0;
        color: $content-description;
      }
    }
  }

  .drop-monitoring-map-component {
    min-height: "559px";
  }

  .table-title-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;

    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $content-description;
    }
  }

  .ant-table-content {
    border-radius: 10px;
    border: 1px solid $border-default !important;
  }

  table {
    thead {
      th {
        border-bottom: 1px solid $border-default !important;
        background: $surface-static-default3 !important;
      }
    }

    tbody {
      td:first-child {
        text-align: center !important;
      }
    }
    tr {
      .ant-table-cell {
        padding: 17px 8px;
      }
    }

    .dropoff-point-text {
      span {
        font-size: 14px;
      }
    }
  }
}
