.wrap-login-page {
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
  .wrap-form {
    max-width: 500px;
    width: 100%;
    padding: 40px;
    background: #ffffff;
    &.form-reset-pass {
      max-width: 570px;
      padding: 0;
      position: relative;
      .ant-progress-inner {
        vertical-align: top;
      }
      .ant-progress {
        position: absolute;
        top: 0;
      }
    }
  }
  form {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }
}

.wrap-forget-pass-action {
  text-align: right;
  margin-bottom: 20px;

  button {
    padding-right: 0px;
  }
}

.wrap-login-action {
  text-align: right;
  button {
    min-width: 80px;
  }
}
