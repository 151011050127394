.task-status-report {
  .title {
    font-family: "IBM Plex Sans Thai Semibold";
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .ant-alert-info {
    border-radius: 4px;
    border: 1px solid var(--alias-border-cta-color-border-info, #d9deeb);
    background: var(--alias-background-status-color-bg-info, #f4f5f8);
    color: #201c00;

    .anticon-info-circle {
      color: #929cb3;
    }
  }
  .ant-alert-error {
    border-radius: 4px;
    border: 1px solid var(--alias-border-cta-color-border-error, #f6baba);
    background: var(--alias-background-status-color-bg-error, #fff2f2);
    color: #201c00;

    .anticon-close-circle {
      color: #e74747;
    }
  }

  &.noti-success {
    border-radius: 8px !important;
    border: 1px solid var(--alias-border-cta-color-border-success, #b3dbb5) !important;
    background: var(--alias-background-status-color-bg-success, #ecf6ed) !important;
  }

  @media (max-width: 1200px) {
    label:has(.button-label) {
      display: none;
    }
  }

  .ant-picker-range {
    .ant-picker-input {
      input {
        text-align: center;
      }
    }
  }
}
