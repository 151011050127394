@import "design-system/src/styles/typography.scss";
@import "design-system/src/styles/_variables.scss";

.external-access-token-content {
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font: $WebH3;
    margin-bottom: 0;
  }

  .description-text {
    font: $WebBody2;
    color: $content-description;
    margin-bottom: 0;
  }

  .text-caption {
    font: $WebCaption;
    color: $content-description;
  }

  .external-access-token-card {
    padding: 12px;
  }

  .api-key-container {
    width: 100%;
    display: flex;
    padding-top: 20px;
    align-items: center;

    .input-api-key {
      height: 42px;

      &:hover,
      &:focus,
      &:focus-within,
      &:active {
        border-color: $border-primary-default;
        box-shadow: none;
      }
    }

    button {
      margin-left: 16px;
    }
  }

  .table-list-token {
    margin-top: 32px;

    .delete-token-button {
      color: $surface-action-delete-default;

      &:hover {
        color: $surface-action-delete-hover;
      }
      &:active {
        color: $surface-action-delete-active;
      }

      &:disabled {
        color: $content-disabled;
      }
    }
  }
}
